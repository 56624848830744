import { Component } from "react"
import { css } from '@emotion/react'
import { Container ,Grid, Typography} from '@material-ui/core';
import { withThemeProps } from "@findep/microfronts-core"
import PropTypes from 'prop-types'
// FINANCIERA COMPONENTS
import {  CardElevation } from '@findep/mf-landings-core'
import financieraImg from '../../../assets/images/footers/Financiara-Independencia.png'
//ICONS MATERIAL UI


 
 const tamanio = css`
 font-size: 18px;
 `
 const cssLink = css`
 color: #3f5414;
 `
  const imgCss = css`
 width: 50%;
 float: right;
 `
 
class InversionistasCmp extends Component {    

    render() {
        const {
            titlePrimary='Información para',
            titleIcon='Inversionistas',
            txtTitle=<span>Apoyo Financiero es parte de la familia<br/><br/><span css={tamanio}><strong>Financiera Independencia</strong></span><br/><br/>A lo largo de nuestra historia hemos logrado que nos identifiquen como una de las principales empresas en el mercado de microcréditos en California, gracias a nuestra solidez financiera y conductas apegadas a nuestros valores que nos permiten continuar apoyando a la comunidad; filosofía que nos identifica y que nos compromete a ser mejores cada día.<hr/></span>,
            txtLink=<span><a href="https://findep.mx/" css={cssLink} target="_blank" rel="nofollow noopener noreferrer"> Para saber más acerca de Financiera Independencia <br/>da click aquí </a></span>
      } = this.props
        return (
            <Container justify="center">
                <br/><br/>
                <Grid container justify="left">
                    <Grid item style={{ textAlign: "left" }}>
                        <Typography component='h3' variant="h6" >
                            <strong style={{color:"#e66728"}}>{titlePrimary}</strong>                        
                        </Typography>

                        <Typography component='h3' variant="h4" >
                        <strong style={{color:"#698d21"}}>{titleIcon}</strong>                        
                        </Typography>
                                              
                    </Grid>
                </Grid>
                <br/><br/>
               <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    <CardElevation>
                    <Typography component='subtitle' variant="subtitle" >
                        {txtTitle}                       
                    </Typography>
                  
                    <br/><br/>
                    
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                             <Typography component='subtitle' variant="subtitle" >
                        {txtLink}                       
                    </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <a href="https://findep.mx/" css={cssLink} target="_blank" rel="nofollow noopener noreferrer">
                                <img src={financieraImg} css={imgCss} alt="img-Financiara-Independencia"/>
                            </a>
                        </Grid>
                    </Grid>
                    <hr/>

                    </CardElevation>
                </Grid>
                
                </Grid>
            </Container>

        )
    }
}

InversionistasCmp.propTypes = {      
    titlePrimary: PropTypes.string, 
    titleIcon: PropTypes.string, 
    txtTitle: PropTypes.string, 
    txtLink: PropTypes.string
}

export default withThemeProps(InversionistasCmp, 'VDPNInversionistas')






